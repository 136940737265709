* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

article,
aside,
details,
figcaption,
figure,
picture,
footer,
header,
main,
nav,
section {
	display: block
}

template {
	display: none;
}

[hidden] {
	display: none;
}

html {
	height: 100%;
	font-family: sans-serif;
	font-size: 100%;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	background-color: #fff;
}

body {
	height: 100%;
	overflow-x: hidden;
	margin: 0;
	font-size: calc(14px + .2vw);
	font-weight: 300;
	line-height: 1.5;
	color: #343335;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
	background-color: transparent;
}

a,
a:hover,
a:focus {
	text-decoration: none
}

img {
	display: inline-block;
	vertical-align: middle;
	border-style: none;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

img,
video {
	max-width: 100%;
	height: auto;
}

svg {
	max-width: 100%;
}

ul {
	list-style: none;
}

b,
strong {
	font-weight: 700;
}

h1, h2, h3, h4, h5, h6 {
	font-family: inherit;
	font-weight: 400;
	line-height: 1.25;
}

p {
	margin-bottom: 20px;
}

/* Inline quotes */

q {
	quotes: "“" "”" "‘" "’";
}

q::before {
	content: open-quote;
}

q::after {
	content: close-quote;
}

/* Block quotes */

blockquote {
	quotes: "“" "”";
}

	blockquote p {
		text-indent: -0.41em;
	}

		blockquote p::before {
			content: open-quote;
		}

		blockquote p::after {
			content: no-close-quote;
		}

		blockquote p:last-of-type {
			margin-bottom: 0;
		}

		blockquote p:last-of-type::after {
			content: close-quote;
		}

	blockquote em,
	blockquote footer {
		display: block;
		font-size: 80%;
		color: #999;
	}

	blockquote em::before,
	blockquote footer::before {
		content: "\2014 \00A0";
	}
