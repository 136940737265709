.arr-link {
  position: relative;
  display: inline-block;
  margin-right: 25px;
  padding: 10px 0 30px;
  line-height: 0.875;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  cursor: pointer;
  color: var(--orange);
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.arr-link::after {
  content: "";
  position: absolute;
  top: 30px;
  left: 50%;
  width: 84px;
  height: 20px;
  background-image: url("../images/arrow--orange.svg");
  background-repeat: no-repeat;
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.arr-link:hover::after {
  transform: translateX(15px);
}

p > .arr-link {
  margin-right: 0;
}

p:not(:last-of-type) > .arr-link {
  margin-bottom: 30px;
}

.social-links a {
  display: inline-block;
  padding: 5px;
  transition: all 0.25s linear;
}

.social-links a:focus,
.social-links a:hover {
  transform: scale(1.15);
}

.social-links a + a {
  margin-left: 10px;
}

.social-links a svg {
  fill: var(--gray);
  transition: all 0.25s linear;
}

.social-links a:focus svg,
.social-links a:hover svg {
  fill: #fff;
}

.legal-links {
  display: inline-block;
  padding-left: 10px;
}

.legal-links a::after {
  content: ".";
  display: inline-block;
  margin-left: 1px;
  margin-right: 5px;
  font-size: 16px;
  font-weight: 900;
  color: var(--orange);
}

.zenelements-studio {
  margin-left: auto;
}

.zenelements-studio svg {
  fill: #2d2d2c;
  transition: all 0.25s linear;
}

.zenelements-studio:hover svg {
  fill: var(--gray);
  transform: scale(1.025);
}
