/* Module */

.project--tease {
	position: relative;
	width: 100%;
	padding-left: 25px;
	padding-right: 25px;
}

@media screen and (min-width: 768px) {
	.project--tease {
		display: flex;
	}

	.project--tease > * {
		flex: 0 0 auto;
	}

	.project--tease:nth-child(even) {
		flex-direction: row-reverse;
	}
}

@media screen and (min-width: 1000px) {
	.project--tease {
		padding-left: 0;
	}

	.project--tease:nth-child(even) {
		padding-right: 0;
	}
}

.project + .project {
	margin-top: 25px;
}

@media screen and (min-width: 768px) {
	.project + .project {
		margin-top: 100px;
	}
}


/* Components */

@media screen and (min-width: 768px) {
	.project--tease .project-image {
		width: 60%;
	}
}

@media screen and (min-width: 1280px) {
	.project--tease .project-image {
		width: 70%;
	}
}

@media screen and (min-width: 768px) {
	.project--tease .project-intro {
		width: 40%;
	}
}

@media screen and (min-width: 1280px) {
	.project--tease .project-intro {
		width: 30%;
	}
}

	.project--tease .project-intro-inner {
		position: relative;
		z-index: 1;
		overflow: hidden;
		padding: 25px;
		background-color: #fff;
		border: 1px solid var(--gray-light);
	}

	@media screen and (min-width: 768px) {
		.project--tease .project-intro-inner {
			margin-top: -30px;
		}

		.project--tease:nth-child(odd) .project-intro-inner {
			margin-left: -30px;
		}

		.project--tease:nth-child(even) .project-intro-inner {
			margin-right: -30px;
		}
	}

	@media screen and (min-width: 1000px) {
		.project--tease .project-intro-inner {
			padding: 50px;
		}
	}


.project-header {
	padding: 25px;
	color: #fff;
	background-color: var(--black);
}

@media screen and (min-width: 768px) {
	.project-header {
		position: relative;
		display: flex;
		align-items: center;
		margin: -75px 50px 50px;
	}
}

@media screen and (min-width: 1400px) {
	.project-header {
		padding: 50px;
	}
}

	@media screen and (max-width: 767px) {
		.project-header-content {
			padding-left: 30px;
		}
	}

	@media screen and (min-width: 768px) {
		.project-header-content {
			flex: 0 0 auto;
			width: 70%;
			padding-right: 50px;
		}
	}

	@media screen and (min-width: 1400px) {
		.project-header-content {
			width: 80%;
			padding-right: 200px;
		}
	}

		.project-header-content .project-title {
			position: relative;
			margin-bottom: 25px;
		}

		.project-header-content .project-title::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 0;
			display: block;
			width: 34px;
			height: 1px;
			background-color: var(--orange);
		}

		.project-header-content .project-text {
			margin-bottom: 0;
		}

	.project-header-details {
		position: relative;
		padding: 25px;
		font-size: 12px;
		text-align: center;
	}

	@media screen and (min-width: 768px) {
		.project-header-details {
			flex: 0 0 auto;
			width: 30%;
			margin-top: -25px;
			margin-bottom: -25px;
			padding-left: 50px;
			padding-right: 0;
			padding-bottom: 0;
		}
	}

	@media screen and (min-width: 1400px) {
		.project-header-details {
			width: 20%;
		}
	}

		.project-header-details svg {
			position: absolute;
		}

		@media screen and (max-width: 767px) {
			.project-header-details svg {
				bottom: -50px;
				left: calc(50% - 10px);
				height: 200px;
			}
		}

		@media screen and (min-width: 768px) {
			.project-header-details svg {
				bottom: -25px;
				left: 0;
				height: 300px;
			}
		}

		@media screen and (max-width: 767px) {
			.project-header-details ul {
				display: flex;
				flex-wrap: wrap;
			}

			.project-header-details ul > li {
				flex: 1 0 auto;
				width: 50%;
				padding: 0 10px;
			}
		}

		.project-header-details li {
			margin-bottom: 20px;
		}

		.project-header-details .label,
		.project-header-details .info {
			display: block;
		}

		.project-header-details .label {
			font-weight: 400;
			color: var(--gray-dark);
		}


	.project-blockquote {
		padding: 50px 25px;
	}

	.project-gallery,
	.project-content {
		padding-top: 50px;
		padding-bottom: 50px;
	}


/* Typography */

.project-categories {
	font-size: 10px;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: .125em;
	color: var(--gray-dark);
}

	.project-categories a {
		color: var(--gray-dark);
	}


.project--tease h3 {
	margin-bottom: 10px;
	font-size: 28px;
}

.project--tease p {
	font-size: 14px;
}
