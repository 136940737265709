[class^="boxes"] {
	position: absolute;
}


/* Left */

[class^="boxes"][class*="left"] {
	left: -284px;
}

[class^="boxes"][class*="left-a"] {
	margin-top: -300px;
}

[class^="boxes"][class*="left-e"] {
	margin-top: -50px;
}

	.commercial [class^="boxes"][class*="left-a"] {
		margin-top: -170px;
	}

[class^="boxes"][class*="left-b"] {
	margin-top: -250px;
}

	@media screen and (min-width: 480px) {
		.homepage [class^="boxes"][class*="left-b"] {
			z-index: -1;
			margin-top: -300px;
		}
	}


/* Right */

[class^="boxes"][class*="right"] {
	right: 0;
}

[class^="boxes"][class*="right-a"] {
	margin-top: 100px;
}

@media screen and (min-width: 768px) {
	[class^="boxes"][class*="right-a"] {
		margin-top: 0;
	}
}

@media screen and (max-width: 720px) {
	[class^="boxes"][class*="right-d"] {
		display: none;
	}
}

[class^="boxes"][class*="right-d"] {
	margin-top: -345px;
}

[class^="boxes"][class*="right-e"] {
	z-index: -1;
	margin-top: -720px;
}
