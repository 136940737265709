
.carousel .flickity-enabled {
	position: relative;
}

	.carousel .flickity-enabled:focus {
		outline: none;
	}


.carousel .flickity-viewport {
	position: relative;
	height: 100%;
	overflow: hidden;
}


.carousel .flickity-slider {
	position: absolute;
	width: 100%;
	height: 100%;
}


.carousel .flickity-enabled.is-draggable {
	tap-highlight-color: transparent;
	user-select: none;
}

	.carousel .flickity-enabled.is-draggable .flickity-viewport {
		cursor: move;
		cursor: grab;
	}

	.carousel .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
		cursor: grabbing;
	}


/* Image carousel */

.carousel-images .carousel-image {
	display: block;
	/* set min-width, allow images to determine cell width */
	min-width: 150px;
	max-width: 100%;
	height: 250px;
	margin-right: 10px;
	/* vertically center */
	top: 50%;
	transform: translateY(-50%);
}

@media screen and (min-width: 768px) {
	.carousel-images .carousel-image {
		height: 400px;
	}
}

@media screen and (min-width: 1440px) {
	.carousel-images .carousel-image {
		height: 540px;
	}
}

.carousel.is-fullscreen .carousel-image {
	height: auto;
	max-height: 100%;
}


.carousel-images .carousel-navigation {
	position: relative;
	width: 160px;
	height: 45px;
	background-color: var(--gray-light);
}

@media screen and (max-width: 767px) {
	.carousel-images .carousel-navigation {
		margin: 50px auto 0;
	}
}

@media screen and (min-width: 768px) {
	.carousel-images .carousel-navigation {
		margin-top: 50px;
		margin-left: 50px;
	}
}


.carousel-images .carousel-navigation .carousel-button {
	position: absolute;
    top: 0;
    bottom: 0;
	display: block;
    margin: 0;
	padding: 0;
	line-height: 0;
    width: 45px;
	text-align: center;
	cursor: pointer;
	background-color: #fff;
	border: 1px solid var(--orange);
    border-radius: 0;
	-webkit-appearance: none;
	transition: transform .25s linear;
}

.carousel-images .carousel-navigation .carousel-button--next:hover {
	transform: translateX(10px);
}

.carousel-images .carousel-navigation .carousel-button--previous:hover {
	transform: translateX(-10px);
}

.carousel-images .carousel-navigation .carousel-button:active,
.carousel-images .carousel-navigation .carousel-button:focus {
	outline: 1px solid var(--orange);
	outline-offset: 2px;
}

	.carousel-images .carousel-navigation .carousel-button--next::after,
	.carousel-images .carousel-navigation .carousel-button--previous::before {
		content: "";
		position: absolute;
		top: 13px;
		width: 44px;
		height: 20px;
		background-image: url("../images/arrow--orange.svg");
		background-position: center right;
		background-repeat: no-repeat;
	}

	.carousel-images .carousel-button--previous::before {
		left: -24px;
		transform: rotate(180deg);
	}

.carousel-images .carousel-navigation .carousel-button--previous {
	left: 0;
}

.carousel-images .carousel-navigation .carousel-button--next {
	right: 0;
}

.carousel-images .carousel-navigation .carousel-pagination {
	position: absolute;
    top: 50%;
    left: 32px;
    right: 32px;
    bottom: 0;
    margin-top: -10px;
    font-family: "Helvetica Neue", Helvetica, arial, sans-serif;
    font-size: 10px;
	line-height: 2;
	text-align: center;
    letter-spacing: .125em;
    color: #454554;
}


/* Content carousel */

.carousel-content .carousel-items {
	padding-left: 25px;
	padding-right: 25px;
}

 .carousel-content--cards .carousel-items {
	 padding-right: 0;
 }

@media screen and (min-width: 768px) {
	.carousel-content .carousel-items {
		padding-right: 0;
	}
}

.carousel-content .carousel-item {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100%;
	margin-right: 50px;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid var(--gray-light);
}

.carousel-content--cards .carousel-item {
	width: 70%;
	margin-right: 25px;
}

@media screen and (min-width: 768px) {
	.carousel-content .carousel-item {
		width: 66.6666%;
	}

	.carousel-content--cards .carousel-item {
		width: 33.3333%;
	}
}

@media screen and (min-width: 1100px) {
	.carousel-content--cards .carousel-item {
		width: 25%;
	}
}

	.carousel-content .carousel-item-content {
		flex: 1 1 auto;
		padding: 25px;
	}

	.carousel-content--cards .carousel-item-content {
		padding: 0 !important;
	}

	@media screen and (min-width: 768px) {
		.carousel-content .carousel-item-content {
			padding: 50px;
		}
	}

		.carousel-content .carousel-item-content .top {
			padding: 25px;
			text-align: center;
			border-bottom: 1px solid var(--gray-light);
		}

			.carousel-content .carousel-item-content .top h3 {
				margin-bottom: 25px;
				font-size: 14px;
				font-weight: 700;
				letter-spacing: .05em;
			}

		.carousel-content .carousel-item-content .bottom {
			margin-top: 0;
			padding: 25px;
		}



.carousel-content .carousel-heading {
	margin-left: 50px;
	padding: 25px;
	color: var(--gray-light);
}

@media screen and (min-width: 600px) {
	.carousel-content .carousel-heading {
		display: none;
	}
}

	.carousel-content .carousel-heading span {
		appearance: none;
		display: inline-block;
		font-size: 24px;
		font-weight: 700;
		line-height: 1;
		cursor: pointer;
		color: var(--gray);
		background: transparent;
		border: 0;
		border-radius: 0;
	}


.carousel-content .carousel-navigation {
	display: none;
}

@media screen and (min-width: 600px) {
	.carousel-content .carousel-navigation {
		display: block;
		margin-left: 50px;
		padding: 25px;
	}
}

@media screen and (min-width: 1000px) {
	.carousel-content .carousel-navigation {
		margin-left: 20%;
	}
}


@media screen and (min-width: 1280px) {
	.carousel-content .carousel-navigation {
		margin-left: 450px;
	}
}

	.carousel-content .carousel-navigation .carousel-link {
		appearance: none;
		display: inline-block;
		font-size: 24px;
		font-weight: 700;
		line-height: 1;
		cursor: pointer;
		color: var(--gray);
		background: transparent;
		border: 0;
		border-radius: 0;
	}

	.carousel-content .carousel-navigation .carousel-link + .carousel-link {
		margin-left: 15px;
	}

		.carousel-content .carousel-navigation .carousel-link:active,
		.carousel-content .carousel-navigation .carousel-link:focus {
			outline: 2px dotted var(--black);
			outline-offset: 2px;
		}

		.carousel-content .carousel-navigation .carousel-link.is-selected {
			color: var(--orange);
		}


/* Backdrops */

.carousel-content--cards .carousel-items::before {
	content: "";
	position: absolute;
	top: -25px;
	left: 0;
	width: 100px;
	height: 250px;
	background-color: var(--gray-dark);
}

@media screen and (min-width: 1000px) {
	.carousel-images--backdrop .carousel-items::before {
		content: "";
		position: absolute;
		top: 100px;
		left: -50px;
		bottom: 100px;
		width: calc(100% + 50px);
		background-color: var(--black);
	}

	.carousel-content--backdrop .carousel-items::before {
		content: "";
		position: absolute;
		z-index: -2;
		top: -75px;
		left: -384px;
		bottom: -75px;
		width: 500px;
		background-color: var(--black);
	}

	.carousel-content--backdrop .carousel-items::after {
		content: "";
		position: absolute;
		z-index: -1;
		top: -20px;
		left: 30px;
		bottom: 50px;
		width: 350px;
		background-color: var(--gray-dark);
	}
}

@media screen and (min-width: 1280px) {
	.carousel-content--backdrop .carousel-items::before {
		width: 800px;
	}
}
