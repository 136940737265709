.directions {
	position: relative;
	z-index: 1;
	display: flex;
	width: 100%;
	height: 675px;
	overflow: hidden;
	background-color: var(--black);
}

@media screen and (min-width: 1950px) {
	.directions {
		height: 60vh;
	}
}

@media screen and (max-width: 1200px) {
	.directions {
		height: 440px;
	}
}

@media screen and (max-width: 767px) {
	.directions {
		flex-direction: column;
		height: auto;
	}
}


.directions-scroll {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 50%;
	width: 1px;
	height: 112px;
	background-color: #fff;
}

@media screen and (max-width: 767px) {
	.directions-scroll {
		display: none;
	}
}


.directions-item {
	position: relative;
	flex: 0 0 auto;
	height: 100%;
	overflow: hidden;
}

.directions--2 .directions-item,
.directions--2 .directions-content-item {
	width: 50%;
}

@media screen and (max-width: 767px) {
	.directions-item {
		width: 100%;
		height: 460px;
	}

	.directions--2 .directions-item,
	.directions--2 .directions-content-item {
		width: 100%;
	}
}

.directions-item--full {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: all .6s ease;
	opacity: 0;
}

.directions--2 .directions-item--full {
	width: 100%;
}


.directions-image {
	width: 100%;
	height: 100%;
}


.directions-content {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	cursor: auto;
	cursor: url("../images/cursor.png"), auto;
}

@media screen and (max-width: 767px) {
	.directions-content {
		flex-direction: column;
	}
}

	.directions-content:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .3);
	}

		.directions-content-item {
			position: relative;
			flex: 0 0 auto;
			height: 100%;
			overflow: hidden;
		}

		@media screen and (max-width: 767px) {
			.directions-content-item {
				height: 50%;
			}
		}


.directions-card {
	width: 33.33%;
	height: auto;
	padding-left: 60px;
	position: absolute;
	bottom: 83px;
	transform: translateY(90px);
	transition: all 1s ease
}

@media screen and (max-width: 1200px) {
	.directions-card {
		bottom: 50px;
		padding-left: 48px;
		transform: translateY(0);
	}
}

@media screen and (max-width: 767px) {
	.directions-card {
		bottom: 59px;
		width: 100%;
		padding-left: 0;
		padding: 0 24px;
		transform: translateY(0);
	}
}

.directions-content-item:hover .directions-card {
	transform: translateY(0px);
}


.directions-heading {
	padding-bottom: 16px;
	font-size: 24px;
	color: #fff;
}

@media screen and (max-width: 767px) {
	.directions-heading {
		margin-bottom: 0;
		padding-bottom: 12px;
		font-size: 31px;
		letter-spacing: -.4px;
	}
}


.directions-text {
	width: 250px;
	padding-bottom: 51px;
	font-size: 14px;
	font-weight: 400;
	color: #fff;
}

@media screen and (max-width: 1200px) {
	.directions-text {
		padding-bottom: 0;
	}
}

@media screen and (max-width: 767px) {
	.directions-text {
		width: 100%;
		padding-bottom: 0;
		font-size: 15px;
		line-height: 24px;
		letter-spacing: .6px;
	}
}


.directions-button {
	position: relative;
	display: inline-block;
	min-width: 120px;
	padding: 5px 15px;
	font-size: 14px;
	font-weight: 400;
	color: #fff;
	border: 1px solid #fff;
	opacity: 0;
	transition: all .8s ease, background .3s ease 0s, color .3s ease 0s, border-color .3s ease 0s;
	transform: translateY(90px);
}

@media screen and (max-width: 767px) {
	.directions-button {
		margin-top: 50px;
		opacity: 1;
		transform: translateY(0);
	}
}

@media screen and (max-width: 1200px) {
	.directions-button {
		margin-top: 21px;
		opacity: 1;
		transform: translateY(0);
	}
}

	.directions-button::before {
		content: "";
		position: absolute;
		top: 6px;
		right: -45px;
		width: 64px;
		height: 20px;
		background-image: url("../images/arrow--white.svg");
		background-position: center right;
		background-repeat: no-repeat;
		transition: transform .5s cubic-bezier(.165,.84,.44,1);
	}

	.directions-button:hover::before {
		transform: translateX(20px);
	}

	.directions-content-item:hover .directions-button {
		opacity: 1;
		transform: translateY(0px);
	}


.directions-lines {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 767px) {
	.directions-lines {
		display: none;
	}
}

	.directions-lines .line {
		position: absolute;
		left: 50%;
		top: 0;
		width: 1px;
		height: 100%;
		background: rgba(255,255,255,0.2);
	}
