.full-width {
	position: relative;
	left: 50%;
	right: 50%;
	width: 100vw;
	margin-left: -50vw;
	margin-right: -50vw;
}


@media only screen and (max-width: 1200px) {
	.horizontal-scroll {
		overflow-x: auto;
		overflow-y: hidden;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		-webkit-overflow-scrolling: touch;
		overflow-scrolling: touch;
		-webkit-transform: translateZ(0);
		transform: translateZ(0);
	}

	.horizontal-scroll::-webkit-scrollbar {
		display: none;
	}
}


.pull-right {
	float: right;
}


.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}


.a11y-hint,
.a11y-text {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	border: 0;
	clip: rect(0 0 0 0);
}
