table {
	width: 100%;
	max-width: 100%;
	border-collapse: collapse;
	margin-bottom: 25px;
	text-align: left;
	background-color: transparent;
}

@media screen and (min-width: 1000px) {
	table {
		width: calc(100% + 50px);
		max-width: calc(100% + 50px);
		margin-right: -50px;
	}
}

table th,
table td {
	padding: 10px 25px;
	vertical-align: top;
	border-top: 1px solid var(--gray-light);
}

table thead th {
	vertical-align: bottom;
	background-color: var(--gray-light);
	border-bottom: 2px solid var(--gray-dark);
}

table tbody + tbody {
	border-top: 2px solid var(--gray-light);
}
