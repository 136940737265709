label {
	font-size: 14px;
}

input,
select,
textarea {
	font-family: inherit;
	font-size: 16px;
	line-height: 1.5;
	border-radius: 0;
	appearance: none;
}

input,
textarea {
	display: inline-block;
	width: 100%;
	margin: 0;
	padding: 10px 25px;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid var(--gray-light);
	transition: border-color .15s ease-in-out;
	outline: 0;
}

select {
	display: inline-block;
	width: 100%;
	height: 44px;
	padding: 10px 25px;
	color: var(--black);
	vertical-align: middle;
	background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 25px center/8px 10px;
	background-color: #fff;
	border: 1px solid var(--gray-light);
	transition: border-color .15s ease-in-out;
	outline: 0;
}

input:focus,
select:focus,
textarea:focus {
	color: var(--black);
	border-color: var(--orange);
	outline: 0;
}

::placeholder {
	color: var(--black);
}

:focus::placeholder {
	color: transparent;
}


.form-field {
	margin-bottom: 15px;
}