.newsletter {
	padding: 50px 25px;
}

	.newsletter p {
		font-size: calc(18px + .2vw);
		line-height: 1.6;
		font-weight: 400;
		color: var(--gray);
	}


.newsletter-form .field-group {
	position: relative;
	max-width: 326px;
	margin-top: 32px;
}

.newsletter-form label {
	display: none;
}

.newsletter-form input {
	width: 100%;
	padding-top: 18px;
	padding-right: 24px;
	padding-bottom: 18px;
	font-size: calc(16px + .2vw);
	line-height: 1.7;
	background-color: white;
	border: 0 none;
	border-bottom: 2px solid var(--orange);
	border-radius: 0;
	transition: border-color .25s cubic-bezier(.25,.46,.45,.94);
}

.newsletter-form input:focus {
	border-color: var(--black);
	outline: 2px dotted var(--orange);
	outline-offset: 10px;
}

.newsletter-form button {
	position: absolute;
	top: 18px;
	right: 0;
	font-size: calc(16px + .2vw);
	font-weight: 700;
	line-height: 1.7;
	text-transform: uppercase;
	cursor: pointer;
	color: #1c1a2a;
	background-color: transparent;
	border: 0 none;
	transition: color .25s cubic-bezier(.25,.46,.45,.94);
	outline: none;
}

.newsletter-form button:focus,
.newsletter-form button:hover {
	color: var(--orange);
}
