[style*="--aspect-ratio"] > :first-child {
	width: 100%;
}

[style*="--aspect-ratio"] > img {
	height: auto;
}

@supports (--custom:property) {
	[style*="--aspect-ratio"] {
		position: relative;
		/* The width portion of the aspect ratio, e.g. 16 in 16:9. */
		--aspect-ratio-w: 1;
		/* The height portion of the aspect ratio, e.g. 9 in 16:9. */
	  	--aspect-ratio-h: 1;
	}

	[style*="--aspect-ratio"]::before {
		content: "";
		display: block;
		padding-top: calc(var(--aspect-ratio-h, 1) / var(--aspect-ratio-w, 1) * 100%);
	}

	[style*="--aspect-ratio"] > :first-child {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
	}
}
