/* Module */

.sitemap {
	display: flex;
	flex-direction: column;
}

@media screen and (min-width: 600px) {
	.sitemap {
		flex-direction: row;
		flex-wrap: wrap;
	}

	.sitemap > * {
		flex: 0 0 auto;
		width: 50%;
		padding-top: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}
}

@media screen and (min-width: 768px) {
	.sitemap > * {
		flex: 1;
		width: auto;
	}
}

@media screen and (min-width: 1000px) {
	.sitemap > * {
		padding-right: 40px;
	}

	.sitemap > *:last-child {
		padding-right: 0;
	}
}


/* Components */

.sitemap-menu li {
	margin-bottom: 5px;
}

	.sitemap-menu ul ul {
		font-size: 11px;
	}

	.sitemap-menu .line {
		position: relative;
	}

		.sitemap-menu .line::before {
			content: "";
			position: absolute;
			top: 10px;
			left: -15px;
			display: inline-block;
			width: 5px;
			height: 2px;
			background-color: var(--orange);
		}


/* Typography */

.sitemap-heading {
	margin-bottom: 10px;
	font-size: 16px;
	color: #fff;
}
