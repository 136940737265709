.awards {
	padding: 0 50px;
}

@media screen and (min-width: 768px) {
	.awards {
		padding: 0;
	}
}


	.awards-item {
		margin-bottom: 15px;
	}

	@media screen and (min-width: 768px) {
		.awards-item {
			display: flex;
			align-items: center;
		}

		.awards-item--reverse {
			flex-direction: row-reverse;
		}
	}

		@media screen and (min-width: 768px) {
			.awards-item-content {
				width: 50%;
			}
		}

		@media screen and (min-width: 1000px) {
			.awards-item-content {
				width: 33.3333%;
			}

			.awards-item--reverse .awards-item-content {
				margin-left: 33.3333%;
			}
		}

			.awards-item-content-inner {
				position: relative;
				display: flex;
				align-items: center;
				width: calc(100% + 30px);
				min-height: 127px;
				margin: 0 -15px;
				padding: 25px;
				background-color: #fff;
				border: 1px solid var(--gray-light);
			}

		@media screen and (min-width: 768px) {
			.awards-item-image {
				width: 50%;
			}
		}

		@media screen and (min-width: 1000px) {
			.awards-item-image {
				width: 33.3333%;
			}
		}


.awards-date {
	writing-mode: vertical-lr;
	float: left;
	margin: 0 30px 0 -15px;
	font-size: 34px;
	font-weight: 900;
	line-height: 1;
	text-transform: uppercase;
	color: var(--gray-light);
}

@supports not (writing-mode: vertical-lr) {
	.awards-date {
		transform: rotate(180deg);
	}
}

.awards-content p:last-of-type {
	margin-bottom: 0;
}
