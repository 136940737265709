/* Module */

.typeset {
	padding-left: 50px;
	padding-right: 25px;
}

@media screen and (min-width: 768px) {
	.typeset {
		padding-right: 50px;
	}
}

.typeset::after {
	content: "";
	display: table;
	clear: both;
}

.typeset + .typeset {
	margin-top: 25px;
}


.typeset h2,
.typeset h3,
.typeset h4,
.typeset h5 {
	margin-bottom: 10px;
	font-weight: 500;
}

.typeset h3 {
	font-size: 16px;
	letter-spacing: .15em;
}

.typeset h4 {
	font-size: 14px;
	text-transform: uppercase;
}


.typeset a {
	color: var(--orange);
}

.typeset a:not([class]) {
	border-bottom: 1px solid transparent;
	transition: color .5s cubic-bezier(.165,.84,.44,1), border-color .5s cubic-bezier(.165,.84,.44,1);
}

.typeset a:not([class]):focus,
.typeset a:not([class]):hover {
	color: var(--black);
	border-color: currentColor;
}


.typeset p {
	margin-bottom: 25px;
}


.typeset ol:not([class]),
.typeset ul:not([class]) {
	margin-bottom: 30px;
	margin-left: 16px;
}

.typeset ul:not([class]) {
	list-style: disc;
}


.typeset blockquote {
	position: relative;
	padding-left: 30px;
	font-size: calc(14px + .4vw);
	font-style: italic;
	color: var(--gray-dark);
}

	.typeset blockquote::before {
		content: "\201C";
		position: absolute;
		top: -40px;
		left: -20px;
		z-index: -300;
		font-family: Georgia, serif;
		font-size: 60px;
		font-weight: 700;
		color: var(--orange);
	}


.typeset small {
	font-size: 14px;
	color: var(--gray-dark);
}

.typeset .large {
	font-size: 32px;
	font-weight: 400;
}


/* Modifiers */

.typeset--center {
	max-width: 720px;
	margin-left: auto;
	margin-right: auto;
}


.typeset--columns {
	column-count: 2;
	column-width: 480px;
	column-gap: 50px;
	column-fill: balance;
}

	.typeset--columns hr {
		border: none;
	}

@supports (column-width: 480px) {
	@media screen and (min-width: 1300px) {
		.typeset--columns p {
			text-align: justify;
		}

		.typeset--columns hr {
			break-after: column;
		}
	}
}


/**
 * Remove indent and trailing whitespace
 */

.typeset--flush {
	padding-right: 0;
	padding-left: 0;
}

.typeset--flush > *:last-child {
	margin-bottom: 0;
}


.typeset--no-indent {
	padding-left: 0;
	padding-right: 0;
}


.typeset--no-title {
	padding-left: 0;
}

	.typeset--no-title p:first-of-type {
		font-size: calc(20px + .2vw);
		line-height: 1.6;
	}

	.typeset--no-title p:first-of-type,
	.typeset--no-title p:first-of-type ~ p {
		padding-left: 50px;
	}

		.typeset--no-title p:first-of-type::before {
			content: "";
			display: inline-block;
			width: 34px;
			height: 1px;
			vertical-align: middle;
			margin-right: 16px;
			margin-bottom: 4px;
			margin-left: -50px;
			background-color: var(--orange);
		}


.typeset--first p:first-of-type {
	font-size: calc(16px + .2vw);
}


/* Components */

.title {
	margin-bottom: 10px;
	padding-left: 50px;
}

h1.title {
	font-size: calc(34px + .2vw);
}

h2.title {
	font-size: calc(28px + .2vw);
}

	.title::before {
		content: "";
		display: inline-block;
		width: 34px;
		height: 1px;
		vertical-align: middle;
		margin-right: 16px;
		margin-bottom: 4px;
		margin-left: -50px;
		background-color: var(--orange);
	}

	.title ~ * {
		padding-left: 50px;
	}

		.typeset .title {
			column-span: all;
			margin-bottom: 50px;
			padding-left: 0;
		}

		.typeset .title ~ * {
			padding-left: 0;
		}


[class^="overlay-h"] {
	position: relative;
	margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
	[class^="overlay-h"] {
		margin-bottom: 40px;
	}
}

[class^="overlay-h2"] {
	padding-left: 50px;
}

	[class^="overlay-h2"] h2::before {
		content: "";
		display: inline-block;
		width: 34px;
		height: 1px;
		vertical-align: middle;
		margin-right: 16px;
		margin-bottom: 4px;
		margin-left: -50px;
		background-color: var(--orange);
	}

	[class^="overlay-h"] span {
		color: var(--gray);
	}

	@media screen and (min-width: 768px) {
		[class^="overlay-h"] span {
			position: absolute;
			left: 0;
			font-size: 48px;
			font-weight: 900;
			color: var(--gray-light);
		}

		[class^="overlay-h3"] span {
			top: -24px;
		}

		[class^="overlay-h2"] span {
			top: -30px;
			left: 50px;
			font-size: 64px;
		}

		[class^="overlay-h"] h2,
		[class^="overlay-h"] h3 {
			position: relative;
			z-index: 1;
		}

		[class^="overlay-h"] h2 {
			font-size: 28px;
		}

		[class^="overlay-h"] h3 {
			font-size: 18px;
		}
	}

	@media screen and (min-width: 1280px) {
		[class^="overlay-h"] span {
			font-size: 82px;
		}

		[class^="overlay-h3"] span {
			top: -44px;
		}

		[class^="overlay-h2"] span {
			top: -38px;
			left: 50px;
		}

		[class^="overlay-h"] h2 {
			font-size: 34px;
		}

		[class^="overlay-h"] h3 {
			font-size: 24px;
		}
	}
