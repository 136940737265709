/* Module */

.content-banner {
	position: relative;
	z-index: 1;
}

	.content-banner--overlay::before {
		content: "";
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 284px;
		height: 30px;
		background-color: var(--gray-light);
		opacity: .4;
	}

	.content-banner--space-top {
		margin-top: 100px;
	}


.content-section {
	position: relative;
	padding-top: 25px;
	padding-bottom: 25px;
}

@media screen and (min-width: 1000px) {
	.content-section {
		padding-top: 50px;
		padding-bottom: 50px;
	}
}


.content-section--no-pt {
	padding-top: 0;
}


.content-section--contain {
	border-bottom: 1px solid var(--gray-light);
}

.content-section--contain-top {
	margin-top: 100px;
	border-top: 1px solid var(--gray-light);
}

	.content-section--contain::before,
	.content-section--contain-top::before {
		content: "";
		position: absolute;
		z-index: 1;
		left: 0;
		width: 284px;
		height: 30px;
		background-color: var(--gray-dark);
	}

	.content-section--contain::before {
		bottom: 0;
	}

	.content-section--contain-top::before {
		top: -30px;
	}


/* Components */

.content-anchor {
	position: absolute;
	top: 0;
	left: 0;
}


.content-block {
	padding-top: 25px;
	padding-bottom: 25px;
}


	.content-block--flush {
		padding-top: 0;
		padding-bottom: 0;
	}


	.content-block--narrow {
		max-width: 720px;
		margin-left: auto;
		margin-right: auto;
	}


	.content-block--columns {
		display: flex;
		flex-wrap: wrap;
		margin-top: -20px;
		overflow-x: hidden;
	}

		.content-block--columns > * {
			flex: 1 0 300px; /* 300px flex basis but can grow to use the available space (`1`) but not shrink (`0`) */
			margin-top: 30px;
		}

	.content-block--image-top-bottom > .content-image-top {
		margin-bottom: 50px;
	}

	.content-block--image-top-bottom > .content-image-bottom {
		margin-top: 50px;
	}

	@media screen and (min-width: 1311px) {
		.content-block--image-top-bottom::after {
			content: "";
			display: table;
			clear: both;
		}

		.content-block--image-top-bottom > .content-image-top,
		.content-block--image-top-bottom > .content-image-bottom {
			width: 50%;
		}

		.content-block--image-top-bottom > .content-image-bottom {
			float: right;
		}
	}


	.content-block--image-left img,
	.content-block--image-right img {
		margin-left: -50px;
	}

	@media screen and (min-width: 1311px) {
		.content-block--image-left img,
		.content-block--image-right img {
			width: 50%;
			width: calc(50% + 25px);
		}

		.content-block--image-left img {
			padding-right: 50px;
		}

		.content-block--image-right img {
			float: right;
			margin-left: 0;
			margin-right: -50px;
		}

		.content-block--image-left .title {
			position: absolute;
			top: 15%;
			left: 48%;
		}

		.content-block--image-left .title ~ p {
			position: absolute;
			top: 25%;
			left: 48%;
		}

		.content-block--image-left .title ~ p,
		.content-block--image-right .title ~ p {
			width: 50%;
		}
	}


.content-navigation {
	display: flex;
	align-items: flex-end;
	margin: 0 30px 50px 50px;
	padding: 0 5px;
	border-bottom: 1px solid var(--gray-light);
}

@media screen and (min-width: 1000px) {
	.content-navigation {
		margin-left: 0;
	}
}

	.content-navigation-label {
		font-family: "Helvetica Neue", Helvetica, arial, sans-serif;
		font-size: 12px;
		line-height: 2;
		letter-spacing: .125em;
		color: #454554;
	}

	.content-navigation-items {
		margin-left: auto;
		font-size: 14px;
	}

	.content-navigation-items a {
		display: inline-block;
		color: var(--gray-dark);
		border-bottom: 1px solid transparent;
		transition: border-color .25s linear;
	}

	.content-navigation-items a + a {
		margin-left: 10px;
	}

	.content-navigation-items a:focus,
	.content-navigation-items a:hover {
		border-color: var(--orange);
	}

	.content-navigation-items a.is-active {
		color: var(--black);
		border-color: var(--orange);
	}


/* Typography */

.content-subtitle {
	display: inline-block;
	padding-left: 60px;
	padding-right: 60px;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: .125em;
}

	.content-subtitle::before {
		content: "";
		display: inline-block;
		width: 34px;
		height: 1px;
		vertical-align: middle;
		margin-right: 26px;
		margin-bottom: 4px;
		margin-left: -60px;
		background-color: var(--orange);
	}

	.content-subtitle::after {
		content: "";
		display: inline-block;
		width: 44px;
		height: 20px;
		vertical-align: middle;
		margin-bottom: 4px;
		margin-left: 26px;
		background-image: url("../images/arrow--gray-light.svg");
		background-position: center right;
		background-repeat: no-repeat;
	}


.content-title {
	font-size: calc(34px + .2vw);
}

.content-subtitle ~ .content-title {
	padding-left: 60px;
	line-height: 1;
}


.typeset .content-links {
	margin-left: -50px;
}

	.content-links > li {
		display: flex;
		align-items: center;
		margin-bottom: 5px;
	}

		.content-links > li svg {
			margin-right: 10px;
		}

			.content-links > li svg line,
			.content-links > li svg polyline {
				transition: stroke .25s linear;
			}

			.content-links > li:hover svg line,
			.content-links > li:hover svg polyline {
				stroke: var(--orange);
			}
