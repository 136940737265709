@media screen and (max-width: 999px) {
	.fullscreen-hit-area {
		display: none;
	}
}

.fullscreen-hit-area {
	position: fixed;
	z-index: 2;
	top: 0;
	width: 284px;
	height: 30px;
	padding: 4px;
	text-align: left;
	cursor: pointer;
	color: #fff;
	background-color: transparent;
	border: 0;
	outline: none;
	opacity: 0;
	transition: all .25s cubic-bezier(.165,.84,.44,1);
}

.fullscreen-hit-area svg {
	background-color: var(--black);
}

.fullscreen-hit-area:active,
.fullscreen-hit-area:hover {
	opacity: 1;
}


@media screen and (min-width: 1000px) {
	#document.enhanced .global-header-logo {
		transition: transform .25s cubic-bezier(.25,.46,.45,.94);
	}

	#document.enhanced.fullscreen .global-nav,
	#document.enhanced.fullscreen .global-header-logo {
		transform: translateX(-284px);
	}

	#document.enhanced .content {
		transition: all .25s cubic-bezier(.25,.46,.45,.94);
	}

	#document.enhanced.fullscreen .content {
		padding-left: 0;
	}
}
