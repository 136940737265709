.block-grid {
  display: flex;
  flex-wrap: wrap;
}

.block-grid-item {
  flex: 0 0 auto;
  width: 100%;
}

@media screen and (min-width: 680px) {
  .block-grid-item {
    width: 50%;
  }
}

@media screen and (min-width: 480px) {
  .block-grid--3 .block-grid-item {
    width: 50%;
  }

  .block-grid--3 .block-grid-item--double {
    width: 100%;
  }
}

@media screen and (min-width: 1000px) {
  .block-grid--3 .block-grid-item {
    width: 33.3333%;
  }

  .block-grid--3 .block-grid-item--double {
    width: 66.6666%;
  }
}

.block-grid--bordered {
  padding: 25px;
}

.block-grid--bordered > .block-grid-item {
  border: 1px solid var(--gray-light);
}

@media screen and (min-width: 680px) {
  .block-grid--bordered .block-grid-item:nth-child(2n) {
    border-left-width: 0;
  }

  .block-grid--bordered .block-grid-item:nth-child(n + 3) {
    border-top-width: 0;
  }
}

@media screen and (min-width: 1000px) {
  .block-grid--bordered {
    padding: 0;
  }
}

.block-grid--gutters {
  margin-left: -1px;
}

.block-grid--gutters > .block-grid-item {
  padding-top: 1px;
  padding-left: 1px;
}

.block-grid-card {
  position: relative;
  overflow: hidden;
  display: block;
}

.block-grid-card > div {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 10px;
  transform: translateY(calc(100% - 20px - 10px - 10px));
  transition: 0.6s cubic-bezier(0.2, 0.6, 0.3, 1);
  color: #fff;
  background-color: var(--black);
}

.block-grid-card:hover > div {
  transform: translateY(0);
}

.block-grid-card > div > * + * {
  margin-top: 10px;
}
