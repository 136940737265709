.tease-people {
	position: relative;
	cursor: pointer;
}

	.tease-people .people-thumb {
		transition: opacity 0.25s cubic-bezier(.25,.46,.45,.94);
	}

	.tease-people:hover .people-thumb {
		opacity: .1;
	}


	.tease-people .people-info {
		position: absolute;
		z-index: 1;
		top: 50%;
		left: 10%;
		right: 10%;
		text-align: center;
		pointer-events: none;
		color: #fff;
		transform: translate(0, -50%);
		opacity: 0;
	}

	.tease-people:hover .people-info {
		opacity: 1;
		animation: fadeUp .5s cubic-bezier(.25,.46,.45,.94) both;
	}


	.tease-people .people-background {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: var(--orange);
		opacity: 0;
		transform: scale(.85);
		transition: all .25s cubic-bezier(.25,.46,.45,.94);
	}

	.tease-people:hover .people-background {
		opacity: 1;
		transform: scale(1);
	}


.people-callout {
	display: flex;
	flex-direction: column;
	height: 100%;
	padding: 50px;
}

@media screen and (min-width: 1000px) {
	.people-callout {
		padding: 50px 100px;
	}
}

	.people-callout > * {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: center;
	}


.people {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

	.people .people-image {
		width: 100%;
	}

	@media screen and (min-width: 768px) {
		.people .people-image {
			width: 70%;
		}
	}

	.people .people-header {
		width: 100%;
	}

	@media screen and (min-width: 768px) {
		.people .people-header {
			width: 30%;
		}
	}

		.people .people-header-content {
			position: relative;
			padding: 50px;
			background-color: #fff;
		}

		@media screen and (min-width: 768px) {
			.people .people-header-content {
				margin-left: -50px;
				padding: 25px;
			}
		}

		@media screen and (min-width: 1280px) {
			.people .people-header-content {
				padding: 50px;
			}
		}

	.people .people-content {
		width: 100%;
		margin-bottom: 50px;
	}

	@media screen and (min-width: 768px) {
		.people .people-content {
			margin-top: 50px;
		}
	}


.people-name {
	position: relative;
	margin-bottom: 25px;
}

.people-name::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 0;
	display: block;
	width: 34px;
	height: 1px;
	background-color: var(--orange);
}

.people-position {
	margin-bottom: 0;
}
