.typeset img {
	margin-bottom: 25px;
}

.typeset img.alignnone,
.typeset img.alignleft,
.typeset img.alignright {
	float: none;
	display: block;
}

@media screen and (min-width: 1000px) {
	.typeset img.alignleft {
		float: left;
		margin: 0 25px 25px -150px;
	}

	.typeset img.alignright {
		float: right;
		margin: 0 -150px 25px 25px;
	}
}

.typeset img.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.typeset img.size-full {
	width: 100%;
	height: auto;
}


.gallery {
	margin: 0 -10px 25px;
}

	.gallery:after {
		content: "";
		display: table;
		clear: both;
	}

	.gallery-item {
		float: left;
		margin: 0;
		padding: 10px;
	}

		.gallery-item img {
			width: 100%;
		}

		.gallery-columns-1 .gallery-item {
			width: 100%;
			float: none;
		}
		.gallery-columns-2 .gallery-item {
			width: 50%
		}
		.gallery-columns-3 .gallery-item {
			width: 33.3333%
		}
		.gallery-columns-4 .gallery-item {
			width: 25%
		}
		.gallery-columns-5 .gallery-item {
			width: 20%
		}
		.gallery-columns-6 .gallery-item {
			width: 16.6667%
		}
		.gallery-columns-7 .gallery-item {
			width: 14.2857%
		}
		.gallery-columns-8 .gallery-item {
			width: 12.5%
		}
		.gallery-columns-9 .gallery-item {
			width: 11.1111%
		}

		.gallery-columns-2 .gallery-item:nth-child(2n+1),
		.gallery-columns-3 .gallery-item:nth-child(3n+1),
		.gallery-columns-4 .gallery-item:nth-child(4n+1),
		.gallery-columns-5 .gallery-item:nth-child(5n+1),
		.gallery-columns-6 .gallery-item:nth-child(6n+1),
		.gallery-columns-7 .gallery-item:nth-child(7n+1),
		.gallery-columns-8 .gallery-item:nth-child(8n+1),
		.gallery-columns-9 .gallery-item:nth-child(9n+1) {
			clear: left;
		}
