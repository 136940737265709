/* Module */

.article {
	position: relative;
	overflow: hidden;
}

.blog .article + .article {
	padding-top: 25px;
}

	.blog .article + .article::before {
		content: "";
		position: absolute;
		z-index: 1;
		top: 0;
		left: 0;
		width: 284px;
		height: 30px;
		background-color: var(--gray-dark);
	}

	.blog .article + .article::after {
		content: "";
		position: absolute;
		z-index: -2;
		top: 30px;
		left: 0;
		width: 100%;
		height: 1px;
		background-color: var(--gray-light);
	}


.article--tease {
	padding: 25px;
	background-color: #fff;
}

@media screen and (min-width: 768px) {
	.article--tease {
		padding: 50px 25px 25px;
	}
}

	.article--tease .article-image {
		position: relative;
		cursor: auto;
		cursor: url("../images/cursor.png"), auto;
	}

	.article--tease .article-headline {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 15px;
		color: #fff;
		background: var(--black);
		opacity: 0;
		transform: scale(.85);
		transition: all .25s cubic-bezier(.25,.46,.45,.94);
	}

	@media screen and (max-width: 900px) {
		.article--tease .article-headline {
			font-size: 12px;
		}
	}

	@media screen and (min-width: 768px) {
		.article--tease .article-headline {
			padding: 25px;
		}
	}

	.article--tease:hover .article-headline {
		opacity: .95;
		transform: scale(1);
	}

		.article--tease .article-headline a {
			color: var(--orange);
		}

	.article--tease .arr-link {
		position: absolute;
		bottom: 15px;
		right: 50px;
	}


/* Components */

.article-header {
	padding: 50px 0 25px;
}

	.article-header .typeset--first {
		max-width: 620px;
		padding-right: 75px;
	}

@media screen and (min-width: 1000px) {
	.article--full .article-image {
		padding-right: 50px;
	}
}

.article-content {
	max-width: 740px;
	margin: 0 auto;
	padding: 50px 0;
}


/* Typography */

.article-title {
	padding-right: 55px;
}

	.article-title a {
		color: var(--black);
	}
