.pagination {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 50px auto;
	background: #fff;
	cursor: default;
}

.pagination a,
.pagination em,
.pagination > span {
	display: block;
	float: left;
	padding: 10px 20px;
}

@media screen and (max-width: 820px) {
    .pagination a,
    .pagination em,
    .pagination > span {
		padding: 10px;
	}
}

.pagination a,
.pagination em {
	font-style: normal;
	font-weight: 700;
	text-decoration: none;
	color: var(--gray-dark);
}

.pagination a:focus,
.pagination a:hover,
.pagination em:focus,
.pagination em:hover {
	color: var(--orange);
}

.pagination .paginate-current {
	color: var(--orange);
}

.pagination .paginate-next,
.pagination .paginate-previous {
	position: relative;
	width: 150px;
	margin: 0 10px;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;
	color: var(--orange);
	border: 1px solid var(--orange);
}

@media screen and (max-width: 840px) {
    .pagination .paginate-next,
    .pagination .paginate-previous {
		width: auto;
	}

	.pagination .paginate-next {
		padding-right: 50px;
	}

	.pagination .paginate-previous {
		padding-left: 50px;
	}
}

.pagination .paginate-next.disabled,
.pagination .paginate-previous.disabled {
	color: var(--gray-light);
	border: 1px solid var(--gray-light);
}

@media screen and (min-width: 768px) {
	.pagination .paginate-next.disabled::after,
	.pagination .paginate-previous.disabled::after {
		background: url("../images/arrow--gray-light.svg");
		opacity: .5;
	}

	.pagination .paginate-next::after,
	.pagination .paginate-previous::after {
		content: "";
		position: absolute;
		z-index: 2;
		top: 50%;
		width: 84px;
		height: 20px;
		pointer-events: none;
		background: url("../images/arrow--orange.svg");
		background-repeat: no-repeat;
		background-size: contain;
		transition: all .8s ease;
	}

	.pagination .paginate-previous::after {
		right: 80px;
		transform: translateX(0) translateY(-50%) rotate(180deg);
	}

	.pagination .paginate-previous:hover::after {
		transform: translateX(-25px) translateY(-50%) rotate(180deg);
	}

	.pagination .paginate-next::after {
		left: 80px;
		transform: translateX(0) translateY(-50%);
	}

	.pagination .paginate-next:hover::after {
		transform: translateX(25px) translateY(-50%);
	}
}

@media screen and (min-width: 1000px) {
	.pagination .paginate-previous::after {
		right: 125px;
	}

	.pagination .paginate-next::after {
		left: 125px;
	}
}
