button {
	overflow: visible;
	font-family: inherit;
	font-size: 16px;
	line-height: 1.5;
	text-transform: none;
	border-radius: 0;
	-webkit-appearance: button;
}

button::-moz-focus-inner {
	padding: 0;
	border-style: none;
}


[class^="button"] {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	padding: 10px 20px;
	font-family: inherit;
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	color: var(--black);
	background: transparent;
	border: 0 none;
	border-radius: 0;
	transition: all .25s cubic-bezier(.25,.46,.45,.94);
}

[class^="button"]:focus,
[class^="button"]:hover {
	text-decoration: none;
}


[class^="button"]:disabled,
[class^="button"][class*="disabled"] {
	pointer-events: none;
	opacity: .7;
}


/* a11y */

[class^="button"]:active,
[class^="button"]:focus {
	outline: 2px dotted var(--black);
	outline-offset: 2px;
}


/* Modifiers */

[class^="button"][class*="--orange"] {
	color: #fff;
	background: var(--orange);
}
