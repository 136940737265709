@import "_root.css";

@import "_base.css";
@import "_global.css";
@import "_nav.css";

@import "_form.css";
@import "_buttons.css";
@import "_links.css";
@import "_text.css";
@import "_image.css";
@import "_tables.css";

@import "_boxes.css";
@import "_grid.css";
@import "_content.css";
@import "_directions.css";
@import "_article.css";
@import "_project.css";
@import "_people.css";
@import "_awards.css";
@import "_newsletter.css";
@import "_sitemap.css";
@import "_fullscreen.css";

@import "_animations.css";
@import "_aspect-ratio.css";
@import "_carousel.css";
@import "_pagination.css";
@import "_utils.css";

#breakpoint::after {
	content: "xsmall";
	display: none;
}

@media only screen and (max-width: 767px) {
	#breakpoint::after {
		content: "small";
		display: none;
	}
}

@media only screen and (min-width: 768px) {
	#breakpoint::after {
		content: "medium";
		display: none;
	}
}

@media only screen and (min-width: 1000px) {
	#breakpoint::after {
		content: "large";
		display: none;
	}
}
