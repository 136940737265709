
.global-nav {
	position: fixed;
	z-index: 2;
	width: 284px;
	padding: 20px 0;
	background-color: var(--black);
	transition: transform .25s cubic-bezier(.25,.46,.45,.94);
	will-change: transform;
}

@media screen and (max-width: 999px) {
	.global-nav {
		top: 56px;
		transform: translateX(-282px);
	}

	.logged-in .global-nav {
		top: 101px
	}

	.global-nav--visible {
		transform: translateX(0);
	}
}

@media screen and (min-width: 1000px) {
	.global-nav {
		top: 95px;
	}

	.enhanced .global-nav {
		position: absolute;
		top: 500px;
		left: 0;
	}

	.enhanced .global-nav--fixed {
		position: fixed;
		z-index: 2;
		top: 95px;
	}

	.logged-in .enhanced .global-nav--fixed {
		top: 97px;
	}

	.global-nav--hidden {
		pointer-events: none;
		transform: translateX(-284px);
	}
}

	.global-nav-item {
		padding: 5px 20px;
	}

		.global-nav-link {
			position: relative;
			display: block;
			overflow: hidden;
			font-size: 18px;
			font-variant-ligatures: no-common-ligatures;
			font-weight: 300;
			line-height: 1.25;
			text-decoration: none;
			text-rendering: geometricPrecision;
			color: #fff;
			will-change: transform;
		}

			.global-nav-link .link-top,
			.global-nav-link .link-bottom {
				display: inline-block;
				transition: transform .25s ease-in-out;
			}

			.global-nav-link .link-top {
				position: absolute;
				top: 0;
				left: 0;
				transform: translateY(0);
			}

			.global-nav-link .link-bottom {
				font-weight: 700;
				transform: translateY(40px);
			}

			.global-nav-link:hover .link-top {
				transform: translateY(-40px);
			}

			.global-nav-link:hover .link-bottom {
				transform: translateY(0);
			}

	.global-nav-item--selected .global-nav-link::after,
	.archive-project [data-nav-target="projects"] .global-nav-link::after {
		content: "";
		position: absolute;
		top: 1px;
		right: 0;
		width: 84px;
		height: 20px;
		background-image: url("../images/arrow--gray.svg");
		background-repeat: no-repeat;
	}
