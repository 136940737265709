.global-header {
  display: flex;
}

@media screen and (max-width: 999px) {
  .global-header {
    background-color: var(--black);
  }
}

@media screen and (min-width: 1000px) {
  .global-header {
    position: absolute;
    height: 100%;
  }
}

.global-header-logo {
  padding: 10px 10px 10px 60px;
  background-color: var(--black);
}

@media screen and (min-width: 1000px) {
  .global-header-logo {
    position: fixed;
    z-index: 2;
    top: 30px;
    left: 0;
    width: 284px;
    padding: 17px 20px;
  }

  .logged-in .global-header-logo {
    top: 32px;
  }
}

.global-header-button {
  position: fixed;
  z-index: 2;
  top: 10px;
  display: inline-block;
  margin: 0;
  padding: 10px;
  overflow: visible;
  cursor: pointer;
  font: inherit;
  line-height: 1;
  text-transform: none;
  color: inherit;
  background-color: #111;
  border: 0;
  border-left: 2px solid #111;
  border-radius: 0;
  box-shadow: none;
}

.logged-in .global-header-button {
  top: 55px;
}

@media only screen and (min-width: 1000px) {
  .global-header-button {
    display: none;
  }
}

#document {
  position: relative;
  display: none;
}

#document.enhanced {
  display: block;
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
  animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  animation-fill-mode: both;
}

@media screen and (min-width: 1000px) {
  #document.enhanced .content {
    width: 100%;
    padding-left: 284px;
  }
}

.global-footer {
  position: relative;
  margin-top: 50px;
  padding-top: 25px;
  font-size: 14px;
  color: var(--gray);
  background-color: var(--black);
}

@media screen and (max-width: 767px) {
  .global-footer {
    text-align: center;
  }
}

@media screen and (min-width: 1000px) {
  .global-footer {
    padding-top: 50px;
  }
}

.global-footer::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: -30px;
  left: 0;
  width: 284px;
  height: 30px;
  background-color: var(--gray-light);
}

.global-footer a {
  color: var(--gray);
  transition: color 0.25s linear;
}

.global-footer a:focus,
.global-footer a:hover {
  color: var(--gray-light);
  text-decoration: underline;
}

.global-footer span {
  display: block;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.0025em;
  text-transform: uppercase;
  color: var(--gray-dark);
}

.global-footer > svg {
  position: absolute;
  bottom: 0;
  right: 0;
  display: block;
}

.global-footer-sitemap {
  max-width: 1000px;
  margin: 0 auto 30px;
  padding: 0 20px;
}

@media screen and (max-width: 600px) {
  .global-footer-sitemap {
    display: none;
  }
}

.global-footer-info {
  max-width: 1000px;
  margin: 20px auto;
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

@media screen and (min-width: 768px) {
  .global-footer-info {
    justify-content: space-between;
  }
}

.global-footer-info .info h3 {
  margin-bottom: 10px;
  text-align: center;
  color: #fff;
}

.global-footer-colophon {
  display: flex;
  padding: 0 20px 60px;
  font-size: 10px;
  line-height: 1;
  color: var(--gray);
}

@media screen and (min-width: 768px) {
  .global-footer-colophon {
    padding-right: 400px;
    padding-bottom: 10px;
  }
}

@media screen and (min-width: 1000px) {
  .global-footer-colophon {
    margin-top: 40px;
    line-height: 10px;
  }
}
