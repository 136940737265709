.fade-up {
	animation-name: fadeUp;
	animation-duration: .5s;
	animation-timing-function: cubic-bezier(.25,.46,.45,.94);
	animation-fill-mode: both;
}

@keyframes fadeUp {
	0% {
		opacity: 0;
		transform: translate(0, 70px);
	}
	100% {
		opacity: 1;
		transform: translate(0, 0);
	}
}

.fade-in {
	animation-name: fadeIn;
	animation-duration: .5s;
	animation-timing-function: cubic-bezier(.25,.46,.45,.94);
	animation-fill-mode: both;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
